import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView w="100%" textCenter m="0 auto">
          <Parallax bgImage={about} bgImageAlt="Entree Dish" strength={200}>
            <div style={{ height: '300px' }} />
          </Parallax>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          m="0 auto"
          w="100%"
          h="550px"
          maxWidth="1400px"
          image={`url(${about}) center / cover repeat`}
          style={{ backgroundAttachment: 'fixed', backgroundSize: '1440px' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
