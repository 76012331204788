import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={
                'https://order.codefusion.tech/restaurants/bNRqit2inY45G9AUOfcI/locations/vzbBWhOh2OidaSAqs412'
              }
              target="_blank"
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="75%"
                  maxWidth="=240px"
                  alt="Order Pickup Button"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={
                'https://order.codefusion.tech/restaurants/bNRqit2inY45G9AUOfcI/locations/vzbBWhOh2OidaSAqs412'
              }
              target="_blank"
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="25vw"
                  maxWidth="320px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
